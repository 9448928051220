/** @jsx jsx */
import React from 'react'
import { css, jsx } from '@emotion/react'
import Layout from '../components/Layout'
import ThemeWrapper from '../components/Theme'
import { Column, ColumnWrap, ColumnWrapper, Title, Text, Divider, Image } from '@swp/components'
import SiteHeader from '../components/SiteHeader'

export default function Page(props) {
  return (
    <ThemeWrapper>
      <Layout title={"Služby"}>
        <SiteHeader />

        <Column className="css-bv12ja --style2 --full" anim={""} name={"uvod"} animS={"3"} style={{"marginBottom":0,"paddingBottom":208}} border={""} parallax={false} fullscreen={true} css={css`
      
    background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/26402/fc40005818fe4d72a4d4531efa578fef_s=350x_.jpg);
    @media (min-width: 351px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/26402/fc40005818fe4d72a4d4531efa578fef_s=660x_.jpg);
    }
    @media (min-width: 661px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/26402/fc40005818fe4d72a4d4531efa578fef_s=860x_.jpg);
    }
    @media (min-width: 861px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/26402/fc40005818fe4d72a4d4531efa578fef_s=1400x_.jpg);
    }
    @media (min-width: 1401px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/26402/fc40005818fe4d72a4d4531efa578fef_s=2000x_.jpg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (max-width: 350px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/26402/fc40005818fe4d72a4d4531efa578fef_s=660x_.jpg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (min-width: 351px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/26402/fc40005818fe4d72a4d4531efa578fef_s=1400x_.jpg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (min-width: 861px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/26402/fc40005818fe4d72a4d4531efa578fef_s=3000x_.jpg);
    }
  
    `}>
        </Column>


        <Column className="pb--30 pt--30" name={"Obsah"}>
          
          <ColumnWrap className="column__flex --center el--1 flex--center" columns={"1"}>
            
            <ColumnWrapper style={{"maxWidth":""}}>
              
              <Title className="title-box title-box--center fs--36" content={"Výcvik psů"}>
              </Title>

              <Text className="text-box text-box--center" content={"Věřím v pěstování pozitivních celoživotních přátelství se psy pomocí motivační techniky výcviku založené na odměnách, známé také jako \"pozitivní posilování\".&nbsp;<br>Věřím, že budováním vztahu založeném na důvěře a přátelství mohou psi a jejich lidé žít spokojený společný život.<br><br>Poskytuji individuální soukromé výcviky přizpůsobené vždy potřebám pejska i majitele:&nbsp;<br><br>Základní poslušnost&nbsp;<br>Trénink psa pro běžný život<br>Výcvik štěňat včetně socializace<br>Převýchova problematického chování<br><br><br>"}>
              </Text>

              <Divider >
              </Divider>

              <Title className="title-box title-box--left fs--36" content={"<br>Hlídání mazlíčků"}>
              </Title>

              <Text className="text-box text-box--left" content={" Když jste mimo domov, ať už je to služební cesta nebo dovolená, zasloužíte si klid s vědomím, že je o vašeho domácího mazlíčka a případně domov postaráno. Můžete se spolehnout, že se o vašeho mazlíčka postarám s láskou, jako by to byl můj vlastní. Pokud existuje určitý režim, který dodržujete, nebo trasa, kterou pravidelně chodíte, ráda vám pomůžu ji držet! Během celého hlídání vždy posílám aktuální zprávy s obrázky o tom, jak se vašemu miláčkovi daří. Kromě pravidelných procházek, krmení, spoustou mazlení, kartáčování a hraní mohu vyhovět i specifickým potřebám vašeho domácího mazlíčka, jako je například podávání léků. Hlídání je možné jak u mě, tak u vás doma. Vše závisí čistě na dohodě, preferncích a potřebách mazlíčka i majitele."}>
              </Text>

              <Title className="title-box title-box--left fs--36" content={"Venčení a péče"}>
              </Title>

              <Text className="text-box text-box--left" content={"Ať už váš pes potřebuje vyvenčit, když jste v práci, nebo jen chcete, aby nějaký čas strávil kvalitně a aktivně, procházka se mnou je řešení! Všechny procházky zahrnují bezpečné prohlídky okolí a spoustu času na hraní. Extra láska a mazlení jsou zahrnuty bez příplatku! <br><br><br>"}>
              </Text>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column name={"paticka"} style={{"backgroundColor":"var(--color-blend--95)"}}>
          
          <ColumnWrap className="column__flex --center el--1 flex--center" columns={"1"}>
            
            <ColumnWrapper style={{"maxWidth":462}}>
              
              <Image src={"https://cdn.swbpg.com/o/26402/df60f83ccf2f4be8867ec8bcdeed2086.svg"} sizes="(max-width: 1439px) 100vw, 1440px" style={{"maxWidth":100}} srcSet={""}>
              </Image>

              <Text className="text-box" content={"info@dogmom.cz<br>"}>
              </Text>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>

      </Layout>
    </ThemeWrapper>
  )
}